import CardFlip from "./Card";
import "./RenderMap.css";

function RenderMap({ map = [] }) {
  return (
    <>
      {map.map((row, index) => (
        <div className={`row`} key={index}>
          {row.map((tile, index) => (
            <CardFlip key={index} tile={tile} />
          ))}
        </div>
      ))}
    </>
  );
}

export default RenderMap;
