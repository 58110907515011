import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import actions from "../../redux/actions/gameActions";

function BtnPlay({
  text = "Play" || "Play Again" || "Try Again",
  icon = "fas fa-play" || "fas fa-redo-alt" || "fas fa-sync-alt",
}) {
  const dispatch = useDispatch();

  function onClick() {
    dispatch(actions.newGame());
  }

  return (
    <Link className="my-4 c-btn c-px-10" to={"/play"} onClick={onClick}>
      <i className={icon + " me-2"}></i>
      {text}
    </Link>
  );
}
export default BtnPlay;
