import { default as React } from "react";
import { useSelector } from "react-redux";
import BtnPlay from "../../../components/game/BtnPlay";
import CardStatic from "../../../components/game/CardStatic";
import Divider from "../../../components/game/Divider";
import Logo from "../../../components/game/Logo";
import Score from "../../../components/game/Score";
import { levels } from "../../Game/useGame";

export default function Lose() {
  const { level, score } = useSelector((store) => store.game);

  return (
    <div className="min-vw-100 min-vh-100 d-flex flex-column justify-content-center align-items-center p-5">
      {/* Testing */}
      {/* <LevelMap show={true} levels={[1, 2, 3, 4, 5]} level={1} /> */}
      <div className="d-flex flex-column justify-content-center align-items-center">
        <Logo src="../imgs/ends/lose.webp" boxShadow="0 0 10px 0 #e7171770" />
      </div>
      <h1 className="text-center c-text-primary">You Lose</h1>
      <BtnPlay text="Try Again" icon="fas fa-redo-alt" />
      <div className="d-flex flex-row justify-content-center align-items-center mb-4">
        <Score score={score} show m="0" />
        <p className="my-auto ms-4 c-text-primary">
          Level: {level + 1} / {levels.length}
        </p>
      </div>
      <div className="d-flex flex-wrap justify-content-center mt-2">
        {Array.from({ length: 18 / 2 }).map((_, i) => (
          <React.Fragment key={i}>
            <CardStatic
              img={"../imgs/ends/lose.webp"}
              className="c-card-danger"
            />
            <Divider i={i} />
          </React.Fragment>
        ))}
      </div>
    </div>
  );
}
