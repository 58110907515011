function Score({ show, score, m = "mt-4" }) {
  if (!show) return <></>;
  return (
    <div className={`d-flex justify-content-center text-warning ${m}`}>
      <div className="score fs-3">
        <i className="fas fa-star me-2"></i>
        <span>{score}</span>
      </div>
    </div>
  );
}

export default Score;
