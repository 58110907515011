function Circle({
  children,
  size = "2rem",
  border = "border-secondary",
  color = "text-muted",
}) {
  return (
    <div
      style={{ height: size, width: size }}
      className={`rounded-circle outline ${border} border ${color} d-flex justify-content-center align-items-center`}
    >
      <span className="pb-1">{children}</span>
    </div>
  );
}

function Line({ show }) {
  if (!show) return <></>;
  return (
    <span
      style={{ width: "2rem" }}
      className={`border-bottom border-secondary`}
    ></span>
  );
}

function Node({ index, lvl, level, levels }) {
  const text = index + 1;
  const sizeCircle = level === index ? "3rem" : "2rem";
  const showLine = index < levels.length - 1;
  const border = level === index ? "border-white" : "border-secondary";
  const color = level === index ? "text-white" : "text-muted";

  return (
    <>
      <Circle size={sizeCircle} border={border} color={color}>
        {text}
      </Circle>
      <Line show={showLine} />
    </>
  );
}

function LevelMap({ levels, level, show, m = "my-4" }) {
  if (!show) return <></>;
  return (
    <div
      style={{ minHeight: "20vh", minWidth: "50vw" }}
      className="mx-auto text-center p-2 rounded"
    >
      <div
        className={`d-flex flex-row ${m} justify-content-center align-items-center flex-wrap`}
      >
        {/* <i class="fas fa-flag me-4 text-secondary"></i> */}
        {levels
          .filter((lvl) => lvl > 0)
          .map((lvl, index) => (
            <Node
              key={index}
              index={index}
              lvl={lvl}
              level={level}
              levels={levels}
            />
          ))}
      </div>
    </div>
  );
}

export default LevelMap;
