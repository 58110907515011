import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import gameActions from "../../redux/actions/gameActions";
import "./CardFlip.css"; // Asegúrate de crear este archivo

const CardFlip = ({ tile }) => {
  // const game = useSelector((state) => state.game);
  const [isFlipped, setIsFlipped] = useState(undefined);
  const [discovered, setDiscovered] = useState(undefined);
  const dispatch = useDispatch();

  const handleFlip = () => {
    if (!discovered && !isFlipped) {
      setIsFlipped(true);
      setTimeout(() => dispatch(gameActions.addCard({ ...tile })), 700);
    }
  };

  useEffect(() => {
    setDiscovered(tile.discovered);
    if (isFlipped === undefined && !tile.reveal);
    else setIsFlipped(tile.reveal);
    // eslint-disable-next-line
  }, [tile]);

  return (
    <div
      className="c-card-container p-0"
      onClick={handleFlip}
      aria-label={`Card Row ${tile.row} Col ${tile.col}`}
    >
      <div className={`c-card ${isFlipped || discovered ? "flipped" : ""}`}>
        {/* Cara frontal de la carta */}
        <div className="c-card-face c-front">
          <img src="./imgs/moon.png" alt="Reverso" className="c-img" />
        </div>
        {/* Cara trasera de la carta */}
        <div className="c-card-face c-back">
          <img src={tile.img} alt="Frente" className="c-img" />
        </div>
      </div>
    </div>
  );
};

export default CardFlip;
