function Divider({ i }) {
  const isLg = window.innerWidth >= 992;
  if (!isLg) return <></>;

  const cardsEvery = 9;
  const addDiv = (i) => (i + 1) % cardsEvery === 0;
  return <>{addDiv(i) && <div className="w-100"></div>}</>;
}

export default Divider;
