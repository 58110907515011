import React from "react";

function Logo({
  src = "./imgs/moon.png" || "./imgs/win.png" || "./imgs/lose.png",
  boxShadow = "0 0 10px 0 #ffffff70" || "0 0 10px 0 #e7171770",
}) {
  return (
    <img
      src={src}
      alt="logo"
      className="logo mb-4 rounded-circle spin-500"
      style={{ width: "12rem", boxShadow }}
    />
  );
}
export default Logo;
