const newGame = () => ({
  type: "NEW_GAME",
});

const nextLevel = () => ({
  type: "NEXT_LEVEL_GAME",
});

const endGame = () => ({
  type: "END_GAME",
});

const addMap = (map) => ({
  type: "ADD_MAP_GAME",
  payload: map,
});

const removeMap = () => ({
  type: "REMOVE_MAP_GAME",
});

const sumScore = () => ({
  type: "SUM_SCORE_GAME",
});

const subScore = () => ({
  type: "SUB_SCORE_GAME",
});

const addCard = (card) => ({
  type: "ADD_CARD_GAME",
  payload: card,
});

const removeCards = () => ({
  type: "REMOVE_CARDS_GAME",
});

const addCardDiscovered = (amount) => ({
  type: "ADD_CARDS_DISCOVERED_GAME",
  payload: amount,
});

const showCards = () => ({
  type: "SHOWED_CARD_GAME",
});

const cardDiscovered = (card) => ({
  type: "DISCOVERED_CARD_GAME",
  payload: card,
});

const cardReveal = (card) => ({
  type: "REVEAL_CARD_GAME",
  payload: card,
});

const cardsReveal = (state) => ({
  type: "REVEAL_CARDS_GAME",
  payload: state,
});

const actions = {
  newGame,
  nextLevel,
  endGame,
  addMap,
  removeMap,
  sumScore,
  subScore,
  addCard,
  removeCards,
  addCardDiscovered,
  showCards,
  cardDiscovered,
  cardReveal,
  cardsReveal,
};

export default actions;
