function TimerBar({ perc, show }) {
  if (!show) return <></>;
  return (
    <div
      className="progress mt-4 mx-auto"
      style={{ height: "4px", width: "50vw" }}
    >
      <div
        className="progress-bar bg-danger"
        role="progressbar"
        style={{ width: perc + "%" }}
        aria-valuenow="100"
        aria-valuemin="0"
        aria-valuemax="100"
      ></div>
    </div>
  );
}
export default TimerBar;
