import React from "react";

function CardStatic({
  img,
  className = "c-card-primary" || "c-card-danger" || "c-card-success",
}) {
  return (
    <div
      style={{
        backgroundImage: `url(${img})`,
        backgroundSize: "cover",
        repeat: "no-repeat",
        backgroundPosition: "center",
      }}
      className={className}
    ></div>
  );
}

export default CardStatic;
