import React from "react";
import BtnPlay from "../../components/game/BtnPlay";
import CardStatic from "../../components/game/CardStatic";
import Divider from "../../components/game/Divider";
import LevelMap from "../../components/game/LevelMap";
import Logo from "../../components/game/Logo";

export default function MainMenu() {
  return (
    <div className="min-vw-100 min-vh-100 d-flex flex-column justify-content-center align-items-center p-5">
      <div className="d-flex flex-column justify-content-center align-items-center">
        <Logo />
      </div>
      <BtnPlay />
      <div className="d-flex flex-wrap justify-content-center mt-2">
        {Array.from({ length: 18 }).map((_, i) => (
          <React.Fragment key={i}>
            <CardStatic img={"./imgs/a.png"} />
            <Divider i={i} />
          </React.Fragment>
        ))}
      </div>
    </div>
  );
}
