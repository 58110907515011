// import "./Game.css";
import LevelMap from "../../components/game/LevelMap";
import RenderMap from "../../components/game/RenderMap";
import Score from "../../components/game/Score";
import TimerBar from "../../components/game/TimerBar";
import Header from "./components/Header";
import Menu from "./components/Menu";
import useGame from "./useGame";

function Map({ map }) {
  return (
    <div className="mx-auto my-auto">
      <RenderMap map={map.matrix} />
    </div>
  );
}

export default function Game() {
  const game = useGame();
  const { level, levels, map, cronometer, ui } = game;
  const { time, getProgressTimeoutBarWidth } = cronometer;

  return (
    <div className="opacityAnim game d-flex flex-column min-vh-100 min-vw-100">
      <Menu show={ui.menu.show} name="Memorice" />

      <Header show={ui.header.show} />
      <Score show={ui.score.show} score={game.score} />
      {/*  show={!!level} */}
      <TimerBar show={ui.timer.show} perc={getProgressTimeoutBarWidth(time)} />
      <Map map={map} />
      <LevelMap
        show={ui.level.show}
        levels={levels.map((v, i) => i + 1)}
        level={level}
      />
    </div>
  );
}
